import * as React from "react"
import Layout from "../components/layout"
import {graphql} from 'gatsby'
import { Helmet } from 'react-helmet'
import BlockContent from "@sanity/block-content-to-react";


export const query = graphql`
{
  site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    _rawTerms
  }
}
`

// markup
const LocalAreaPage = ({data}) => {

  const site = (data || {}).site

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'terms-page' }} />
<main className="main">
        <section className="container section section-first">
		    <div className="row justify-content-center">
				<div className="col-12 col-lg-10 col-xl-8">
				    <h1 className="title title--h2 js-lines">Terms & Conditions</h1>
				    <BlockContent blocks={site._rawTerms} renderContainerOnSingleChild={true}/>
				</div>
			</div>
		</section>
	</main>
    </Layout>
  )
}

export default LocalAreaPage
